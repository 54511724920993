import LocomotiveScroll from 'locomotive-scroll';



const navbar = document.querySelector("#nav");
const d = {
    scroll: 0,
    lastScrollPosition: 0,
};

window.addEventListener("load", function(event) {
    const scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
    });
    
    scroll.on('scroll', (instance) => {

        d.scroll = instance.scroll.y;

        if(d.scroll > 100) {
            ['shadow-sm'].map(v=> navbar.classList.add(v));
        } else {
            ['shadow-sm'].map(v=> navbar.classList.remove(v));
        }

        if(d.scroll > d.lastScrollPosition && d.scroll > 100) {
            d.lastScrollPosition = d.scroll;
            navbar.classList.add("-translate-y-full");
        }
        if(d.scroll < d.lastScrollPosition) {
            d.lastScrollPosition = d.scroll;
            navbar.classList.remove("-translate-y-full");
        }

    });


    const accordions = document.querySelectorAll(".accordion");

    accordions.forEach(a => {
        a.addEventListener('click', (e) => {
            setTimeout(() => {
                scroll.update();
            }, 1000);
            
        })
    })

});



const body = document.querySelector('body');
const navbarToggles = document.querySelectorAll('.navbarToggle');
const mobileNav = document.querySelector('#mobileNav');

navbarToggles.forEach(toggle => {
    toggle.addEventListener('click', (e) => {
        body.classList.toggle('modal-open');
        ['translate-x-full', 'block'].map(v=> mobileNav.classList.toggle(v));
    })
})


const cookieButton = document.querySelector("#acceptCookie");
const banner = document.querySelector("#cookiebar");
if(document.cookie.indexOf("cookiebar") === -1){
    banner.classList.remove("hidden")
} else {
    banner.classList.add("hidden")
}

cookieButton.addEventListener('click', (e) => {
    e.preventDefault();
    document.cookie = "cookiebar=1;SameSite=Strict;path=/;max-age=864000", banner.classList.add("hidden")
});